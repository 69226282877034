import { convertINotificationResponseToNotificationOverview } from '~/common/converter/notification/notification';
import { convertISeriesResponseToSeriesOverview } from '~/common/converter/series/series';
import type { NotificationOverview } from '~/common/types/notification-overview';
import type { SeriesOverview } from '~/common/types/series-overview';
import { LocalStorageOperator } from '~/infra/local-storage-operator';
import { AppError, ErrorType } from '~/models/app-error';
import { NotificationCategory, NotificationType } from '~/models/query-types';
import { OS } from '~/models/types';
import type { User } from '~/models/user';
import {
  updateMe as repositoryUpdateMe,
  type UpdateMeRequest,
  getNotificationSince,
  type MeGetNotificationCountSinceResponse,
} from '~/repositories/me-repository';
import {
  MeResponse,
  quickQuery,
  type QuickQueryParams,
} from '~/repositories/query-repository';
import { buildSeries } from '@app/_repositories/query-builder';

export const NOTIFICATIONS_PER_PAGE = 30;
export const RECOMMENDED_SERIES_LIMIT = 10;

const FETCH_ME_REQUEST_ID = 'fetchMe';
const LAST_NOTIFICATION_CHECKED_AT_KEY = 'lastNotificationCheckedAt';
const SUPPORTED_NOTIFICATION_TYPES = new Set([
  NotificationType.NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY,
  NotificationType.NOTIFICATION_TYPE_NEW_FOLLOWER,
  NotificationType.NOTIFICATION_TYPE_OPERATION,
  NotificationType.NOTIFICATION_TYPE_NEW_STORY_COMMENT,
  NotificationType.NOTIFICATION_TYPE_NEW_COMMENT_LIKE,
  NotificationType.NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE,
]);

export type NotificationList = {
  notificationList?: NotificationOverview[];
  nextCursor?: string;
  hasNextPage?: boolean;
};

type NotificationCheckedMap = {
  [userId: string]: number;
};

const localStorage = new LocalStorageOperator();

export const fetchMe = async (idToken?: string): Promise<MeResponse> => {
  const res = await quickQuery(
    [
      {
        requestId: FETCH_ME_REQUEST_ID,
        meRequest: {},
      },
    ],
    idToken
  );
  const me = res.response_list.find(
    (response) => response.request_id === FETCH_ME_REQUEST_ID
  )?.me;

  if (!me) {
    throw new AppError(ErrorType.API_REQUEST_404, 'user not found', 404);
  }

  return me;
};

export const updateMe = (user: UpdateMeRequest): Promise<User> =>
  repositoryUpdateMe(user);

export const fetchNotifications = (
  notificationCategory: NotificationCategory,
  cursor = '',
  limit = NOTIFICATIONS_PER_PAGE,
  lastCheckedAt?: Date
): Promise<NotificationList> =>
  new Promise<NotificationList>((resolve, reject) => {
    const params: QuickQueryParams = {
      requestId: `me-notification-list-${cursor.slice(-5)}`,
      rawQueryRequest: {
        me_request: {
          require_user: {
            require_id: true,
            require_notification_page: {
              category: notificationCategory,
              cursor: {
                cursor,
                limit,
                require_cursor_info: {
                  require_has_next_page: true,
                  require_end_cursor: true,
                },
              },
              require_notification_list: {
                require_contents: true,
                require_created_at: true,
                require_id: true,
                require_type: true,
              },
            },
          },
        },
      },
    };
    quickQuery([params]).then((response) => {
      if (typeof response.response_list[0] === 'undefined') {
        reject();
        return;
      }
      const notificationPage =
        response.response_list[0]?.me?.user?.notification_page;
      if (!notificationPage) {
        reject();
        return;
      }

      resolve({
        notificationList:
          notificationPage?.notification_list
            ?.filter((notif) =>
              SUPPORTED_NOTIFICATION_TYPES.has(notif.type as NotificationType)
            )
            ?.map((n) =>
              convertINotificationResponseToNotificationOverview(
                n,
                lastCheckedAt
              )
            ) || [],
        nextCursor: notificationPage?.cursor?.end_cursor || '',
        hasNextPage: notificationPage?.cursor?.has_next_page || false,
      });
    });
  });

export const fetchLastNotificationCheckedAt = (userId: string): Date => {
  const notifications: NotificationCheckedMap | null = localStorage.getObject(
    LAST_NOTIFICATION_CHECKED_AT_KEY
  ) as NotificationCheckedMap | null;
  const notification = notifications?.[userId];
  if (!notification) {
    updateLastNotificationCheckedAt(userId);
    return new Date();
  }

  return new Date(notification);
};

export const updateLastNotificationCheckedAt = (userId: string) => {
  const timestamp = Date.now();
  const notifications: NotificationCheckedMap =
    (localStorage.getObject(
      LAST_NOTIFICATION_CHECKED_AT_KEY
    ) as NotificationCheckedMap | null) ?? {};
  notifications[userId] = timestamp;
  localStorage.setObject(LAST_NOTIFICATION_CHECKED_AT_KEY, notifications);
};

export const fetchNotificationCountSince = (
  userId: string
): Promise<MeGetNotificationCountSinceResponse> => {
  const date = fetchLastNotificationCheckedAt(userId);
  return getNotificationSince({
    os: OS.OS_UNSPECIFIED,
    category: NotificationCategory.NOTIFICATION_CATEGORY_UNSPECIFIED,
    since: date.toISOString(),
  });
};

export type MeRecommendedSeriesList = {
  seriesList: SeriesOverview[];
  hasNextPage: boolean;
};

export const fetchMeRecommendedSeries = (
  page = 0,
  limit = RECOMMENDED_SERIES_LIMIT
): Promise<MeRecommendedSeriesList> =>
  new Promise<MeRecommendedSeriesList>((resolve, reject) => {
    const params: QuickQueryParams = {
      requestId: `me-recommended-series-${page}-${limit}`,
      rawQueryRequest: {
        me_request: {
          require_recommended_series: {
            require_series_page: {
              require_series_list: {
                ...buildSeries(),
                require_recommender_model_id: true,
                require_total_like_count: true,
              },
              page: {
                page_number: page,
                limit,
                require_page_info: {
                  require_has_next_page: true,
                },
              },
            },
            script_type_filter: {
              exclude_video: true,
              exclude_audio: true,
            },
          },
        },
      },
    };
    quickQuery([params]).then((response) => {
      if (typeof response.response_list[0] === 'undefined') {
        reject();
        return;
      }

      const seriesPage = response.response_list[0].me?.recommended_series_page;
      if (!seriesPage) {
        reject();
        return;
      }
      resolve({
        seriesList:
          seriesPage?.series_list?.map((se) =>
            convertISeriesResponseToSeriesOverview(se)
          ) || [],
        hasNextPage: seriesPage?.page?.has_next_page || false,
      });
    });
  });
