import type { NotificationOverview } from '~/common/types/notification-overview';
import { NotificationCategory, NotificationType } from '~/models/query-types';
import type { NotificationResponse } from '~/repositories/query-repository';
import { formatNotificationDateString } from '~/utils/formats';

const assignNotificationCategoryFromType = (
  type?: NotificationType
): NotificationCategory => {
  switch (type) {
    case NotificationType.NOTIFICATION_TYPE_NEW_FOLLOWER:
      return NotificationCategory.NOTIFICATION_CATEGORY_USER_RELATED;
    case NotificationType.NOTIFICATION_TYPE_FOLLOWING_USER_NEW_STORY:
      return NotificationCategory.NOTIFICATION_CATEGORY_FOLLOWING_RELATED;
    case NotificationType.NOTIFICATION_TYPE_OPERATION:
      return NotificationCategory.NOTIFICATION_CATEGORY_OPERATION;
    case NotificationType.NOTIFICATION_TYPE_NEW_COMMENT_LIKE:
      return NotificationCategory.NOTIFICATION_CATEGORY_USER_RELATED;
    case NotificationType.NOTIFICATION_TYPE_NEW_COMMENT_RESPONSE:
      return NotificationCategory.NOTIFICATION_CATEGORY_USER_RELATED;
    case NotificationType.NOTIFICATION_TYPE_NEW_STORY_COMMENT:
      return NotificationCategory.NOTIFICATION_CATEGORY_USER_RELATED;
    default:
      return NotificationCategory.NOTIFICATION_CATEGORY_UNSPECIFIED;
  }
};

const extractUserIdFromUserUrl = (url?: string): string =>
  url ? url.slice(url.indexOf('/user/') + 6) : '';

const extractUserIdFromNewStoryFromFollowingUserMessage = (
  msg: string
): string | undefined => {
  try {
    const result = msg.match(new RegExp("/user/(.*)' "));
    return result?.[1];
  } catch {
    return;
  }
};

export const convertINotificationResponseToNotificationOverview = (
  notificationResponse: NotificationResponse,
  lastCheckedAt?: Date
): NotificationOverview => {
  const isNew = (() => {
    if (!notificationResponse.created_at || !lastCheckedAt) return false;

    const createdAtDate = new Date(notificationResponse.created_at);
    return createdAtDate > lastCheckedAt;
  })();

  const notification: NotificationOverview = {
    id: notificationResponse.id || '',
    notificationDateString:
      formatNotificationDateString(notificationResponse.created_at) || '',
    category: assignNotificationCategoryFromType(
      notificationResponse.type as NotificationType
    ),
    type:
      (notificationResponse.type as NotificationType) ||
      NotificationType.NOTIFICATION_TYPE_UNSPECIFIED,
    isNew,
  };

  if (notificationResponse.contents?.new_follower) {
    notification.iconUrl =
      notificationResponse.contents.new_follower.user_icon?.image_url_list?.[0];
    notification.actionUrl =
      notificationResponse.contents.new_follower.action_url;
    notification.newFollower = {
      messageHtml: notificationResponse.contents.new_follower.message,
      userId: extractUserIdFromUserUrl(notification.actionUrl),
    };
  }

  if (notificationResponse.contents?.following_user_new_story) {
    notification.iconUrl =
      notificationResponse.contents.following_user_new_story.user_icon?.image_url_list?.[0];
    notification.actionUrl =
      notificationResponse.contents.following_user_new_story.action_url;
    notification.newStoryFromFollowingUser = {
      userId: extractUserIdFromNewStoryFromFollowingUserMessage(
        notificationResponse.contents?.following_user_new_story.message || ''
      ),
      messageHtml:
        notificationResponse.contents.following_user_new_story.message,
      storyThumbnailUrl:
        notificationResponse.contents.following_user_new_story.thumbnail_url,
    };
  }

  if (notificationResponse.contents?.operation) {
    notification.iconUrl = notificationResponse.contents.operation.icon_url;
    notification.actionUrl = notificationResponse.contents.operation.action_url;
    notification.operation = {
      title: notificationResponse.contents.operation.title,
      notificationImageUrl:
        notificationResponse.contents.operation.thumbnail_url,
      message: notificationResponse.contents.operation.message,
    };
  }

  // for new_comment_like
  const commentContent =
    notificationResponse.contents?.new_comment_like ??
    notificationResponse.contents?.new_comment_response ??
    notificationResponse.contents?.new_story_comment;

  if (commentContent) {
    notification.iconUrl = commentContent.user_icon?.image_url_list?.[0];
    notification.actionUrl = commentContent.action_url;
    notification.comment = {
      storyTitle: commentContent.story_title,
      storyThumbnailUrl: commentContent.thumbnail_url,
      messageHtml: commentContent.message,
    };
  }

  return notification;
};
