import Axios, { type AxiosError } from 'axios';
import { getFirebaseUser } from '~/lib/firebase';
import { AppError, ErrorType } from '~/models/app-error';

const BASE_URL = `${process.env['NEXT_PUBLIC_API_ENDPOINT']}/twirp`;
const BASIC_CREDENTIALS = `${process.env['API_BASIC_USER']}:${process.env['API_BASIC_PASSWORD']}`;
const ECONNABORTED_CODE = 'ECONNABORTED'; // Request timeout

export const api = Axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(async (req) => {
  const firebaseUser = await getFirebaseUser();
  const idToken = req.params?.idToken || (await firebaseUser?.getIdToken());

  if (req.params?.idToken) delete req.params.idToken;

  if (req.headers) {
    req.headers['Content-Type'] = 'application/json';
    if (idToken) {
      req.headers['Authorization'] = `Bearer ${idToken}`;
    } else {
      const credentials = Buffer.from(BASIC_CREDENTIALS).toString('base64');
      req.headers['Authorization'] = `Basic ${credentials}`;
    }
  }

  return req;
});

api.interceptors.response.use(undefined, (err: AxiosError) => {
  if (!err.response || err.code === ECONNABORTED_CODE) {
    return Promise.reject(
      new AppError(ErrorType.NETWORK_ERROR, 'ネットワークエラーです')
    );
  }

  if (err.response.status === 409) {
    return Promise.reject(
      new AppError(
        ErrorType.API_REQUEST_409,
        '',
        err.response.status,
        err.response
      )
    );
  }

  if (err.response.status === 403) {
    return Promise.reject(
      new AppError(
        ErrorType.API_REQUEST_403,
        'この操作に対する権限がありません',
        err.response.status,
        err.response
      )
    );
  }

  if (err.response.status >= 400 && err.response.status < 500) {
    return Promise.reject(
      new AppError(
        ErrorType.API_REQUEST_404,
        '',
        err.response.status,
        err.response
      )
    );
  }

  if (err.response.status >= 500 && err.response.status < 600) {
    return Promise.reject(
      new AppError(
        ErrorType.API_REQUEST_500,
        '',
        err.response.status,
        err.response
      )
    );
  }

  return Promise.reject(
    new AppError(ErrorType.UNKNOWN_ERROR, '', err.response.status, err.response)
  );
});
