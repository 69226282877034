import type { SnakeCasedPropertiesDeep } from 'type-fest';
import type {
  query as rpcQuery,
  query_pagination,
  query_search_types,
} from '~/infra/api/rpc';
import { api } from '~/infra/api/teller-api';
import { SearchStoryFacetType } from '~/models/query-search-types';
import { StoryScriptType } from '~/models/query-types';
import { StoryStatus } from '~/models/story';
import type {
  MeRequest,
  StoryRequireField,
  UserRequireField,
  UserCursorRequest,
} from './type';

const SERVICE_PATH = '/query.QueryService';

/*
 * Definition of common structures for wrapping Query Service requests
 * from here ↓
 */

type Pagination = {
  page?: number;
  cursor?: string;
  limit: number;
};

/*
 * Request types
 *
 */

type StoryRanking = {
  filterByTag?: string;
  filterOfficial?: boolean; // true: only official, false: only indies, undefined: both
  filterSeries?: boolean;
  pagination?: Pagination;
};

type StoryListFacet = {
  query?: string;
  publishedAtFrom?: Date; // filter to stories published from certain date
  facetLimit: number;
};

type SeriesUserAvailability = {
  seriesId: string;
};

type StoryUserAvailability = {
  storyId: string;
};

type FollowingFollowerList = {
  userId: string;
  pagination: Pagination;
  isFollow: boolean;
};

type PageRequest = SnakeCasedPropertiesDeep<query_pagination.IPageRequest>;
export type QueryRequestItem =
  SnakeCasedPropertiesDeep<rpcQuery.IQueryRequestItem>;

type SearchStoryParams =
  SnakeCasedPropertiesDeep<query_search_types.ISearchStoryParams>;

export type QueryRequest = SnakeCasedPropertiesDeep<rpcQuery.IQueryRequest>;
export type QueryResponse = Required<
  SnakeCasedPropertiesDeep<rpcQuery.IQueryResponse>
>;
export type QueryErrorResponse =
  SnakeCasedPropertiesDeep<rpcQuery.IErrorResponse>;

export type QuickQueryParams = {
  requestId: string;
  meRequest?: MeRequest;
  storyListFacet?: StoryListFacet;
  storyRankingRequest?: StoryRanking;
  seriesUserAvailabilityRequest?: SeriesUserAvailability;
  storyUserAvailabilityRequest?: StoryUserAvailability;
  followingFollowerListRequest?: FollowingFollowerList;
  rawQueryRequest?: QueryRequestItem;
};

/*
 * Methods for generating which fields we want to retrieve for each type
 * from here ↓
 */

// Fields for story
const buildStoryRequireFields = (
  withSeries: boolean,
  withUser: boolean,
  onlyEssential?: boolean,
  withComments?: boolean,
  withLikes?: boolean
): StoryRequireField => {
  if (onlyEssential) {
    return {
      require_id: true,
      require_title: true,
      require_is_official: true,
      require_series: {
        require_id: true,
        require_title: true,
        require_is_completed: true,
        require_is_oneshot: true,
        require_shared_with_status: true,
      },
    };
  }
  const fields: StoryRequireField = {
    require_id: true,
    require_title: true,
    require_description: true,
    require_series_index: true,
    require_published_at: true,
    require_updated_at: true,
    require_tags: true,
    require_availability: true,
    require_is_official: true,
    require_script_type: true,
    require_status: true,
    require_is_admin_sensitive: true,
    require_is_user_sensitive: true,
  };

  if (withLikes) {
    fields.require_like_count = true;
  }

  fields.require_series = withSeries
    ? {
        require_id: true,
        require_title: true,
        require_description: true,
        require_thumbnail: { require_serving_url: true },
        require_tags: true,
        require_has_free_story: true,
        require_latest_published_at: true,
        require_total_like_count: withLikes,
        require_is_completed: true,
        require_shared_with_status: true,
        require_has_novel_script_story: true,
        require_is_oneshot: true,
      }
    : {
        require_id: true,
        require_shared_with_status: true,
      };

  if (withUser) {
    fields.require_user = buildUserFields();
  }

  if (withComments) {
    fields.require_comment_section = {
      require_all_list: {
        cursor: {
          limit: 3,
          require_cursor_info: {
            require_total_count: true,
          },
        },
        require_comment_list: {
          require_id: true,
          require_user: buildUserFields(),
          require_text_body: true,
          require_stamp_body: {
            require_image: {
              require_serving_url: true,
            },
          },
        },
      },
    };
  }
  return fields;
};

// Fields for user
const buildUserFields = (
  withFollowNumbers = false,
  withCoverImage = false,
  withSearchableStoryCount = false,
  withProfile = false,
  onlyEssential = false
): UserRequireField => {
  if (onlyEssential) {
    return {
      require_id: true,
      require_name: true,
    };
  }

  const userRequireFields: UserRequireField = {
    require_id: true,
    require_name: true,
    require_vip_status: true,
    require_thumbnail: {
      require_id: true,
      require_serving_url: true,
    },
    require_user_role: true,
  };
  if (withFollowNumbers) {
    const requireCount: UserCursorRequest = {
      cursor: {
        require_cursor_info: {
          require_total_count: true,
        },
      },
    };

    userRequireFields.require_followee = requireCount;
    userRequireFields.require_follower = requireCount;
  }

  if (withCoverImage) {
    userRequireFields.require_cover_image = {
      require_id: true,
      require_serving_url: true,
    };
  }

  if (withSearchableStoryCount) {
    userRequireFields.require_searchable_story_count = true;
  }
  if (withProfile) {
    userRequireFields.require_profile = true;
  }
  return userRequireFields;
};

// Fields for story including also series and user information
// In some cases we only want the user information, that's the `onlyUser` flag
// For search suggestions, we only need id, seriesId, title and isOfficial, that's the `onlyEssential` flag
const buildRequireStoryPage = (
  series = true,
  user = true,
  onlyUser = false,
  onlyEssential = false,
  userProfileAndStoryCount = false,
  withLikes = true
): StoryRequireField => {
  if (onlyUser) {
    return {
      requireUser: buildUserFields(
        false, // no follow numbers
        false, // no cover image
        userProfileAndStoryCount, // profile text
        userProfileAndStoryCount // story count
      ),
    } as StoryRequireField;
  }
  const storyRequireField = buildStoryRequireFields(
    series,
    user,
    onlyEssential,
    false, // no comments,
    withLikes // like count
  );

  return storyRequireField;
};

// Common method for creating basic QueryRequestItem
const buildRequestItem = (requestId: string): QueryRequestItem => ({
  request_id: requestId,
});

const buildPageRequest = (
  page = 0,
  limit: number,
  requireTotalCount = true
): PageRequest => {
  const pageRequest: PageRequest = {
    page_number: page,
    limit,
  };
  if (requireTotalCount) {
    pageRequest.require_page_info = {
      require_total_count: true,
    };
  }

  return pageRequest;
};

/*
 * Types of requests that can be send to query service API
 * from here ↓
 */

// Story selections
const buildMeRequest = (requestId: string): QueryRequestItem => {
  const queryRequestItem = buildRequestItem(requestId);
  queryRequestItem.me_request = {
    require_user: buildUserFields(
      true, // with follow numbers
      true, // with cover image
      true, // with searchable story count
      true // with profile,
    ),
    require_show_sensitive: true,
  };
  return queryRequestItem;
};

// Story list but aim for tag facet list
const buildStoryListFacet = (
  storyList: StoryListFacet,
  requestId: string
): QueryRequestItem => {
  const queryRequestItem = buildRequestItem(requestId);
  const params: SearchStoryParams = {
    filter: {
      filter_any_status: [StoryStatus.PUBLISH],
    },
  };
  if (storyList.publishedAtFrom && params.filter) {
    params.filter.filter_published_at = {
      // TODO: ISO で正しいかどうか未確認、確認して間違ってたら修正する
      greater_than_equal: storyList.publishedAtFrom.toISOString(),
    };
  }
  queryRequestItem.search_story_request = {
    params,
    require_facet_list: {
      facet_type: [SearchStoryFacetType.SEARCH_STORY_FACET_TYPE_TAGS],
      limit_per_facet: storyList.facetLimit,
    },
  };
  return queryRequestItem;
};

const buildStoryRankingRequest = (
  storyRankingRequest: StoryRanking,
  requestId: string
): QueryRequestItem => {
  const queryRequestItem = buildRequestItem(requestId);
  queryRequestItem.story_ranking_request = {
    require_search_story_page: {
      params: {
        filter: {
          filter_any_script_type: [
            StoryScriptType.STORY_SCRIPT_TYPE_CHAT_NOVEL,
            StoryScriptType.STORY_SCRIPT_TYPE_NOVEL,
          ],
        },
      },
      require_story_page: {
        require_story_list: buildRequireStoryPage(),
      },
    },
  };
  if (
    queryRequestItem.story_ranking_request?.require_search_story_page?.params
      ?.filter
  ) {
    if (storyRankingRequest.filterByTag) {
      queryRequestItem.story_ranking_request.require_search_story_page.params.filter.filter_tag =
        [storyRankingRequest.filterByTag];
    }
    if (storyRankingRequest.filterOfficial !== undefined) {
      if (storyRankingRequest.filterOfficial) {
        queryRequestItem.story_ranking_request.require_search_story_page.params.filter.exclude_not_official =
          true;
      } else {
        queryRequestItem.story_ranking_request.require_search_story_page.params.filter.exclude_official =
          true;
      }
    }
    if (storyRankingRequest.filterSeries) {
      queryRequestItem.story_ranking_request.require_search_story_page.params.filter.exclude_not_series =
        true;
    }
  }
  if (
    storyRankingRequest.pagination &&
    queryRequestItem.story_ranking_request.require_search_story_page
      ?.require_story_page
  ) {
    queryRequestItem.story_ranking_request.require_search_story_page.require_story_page.page =
      buildPageRequest(
        storyRankingRequest.pagination.page,
        storyRankingRequest.pagination.limit,
        true // require total results
      );
  }
  return queryRequestItem;
};

const buildSeriesUserAvailabilityRequest = (
  seriesId: string,
  requestId: string
): QueryRequestItem => {
  const queryRequestItem = buildRequestItem(requestId);
  queryRequestItem.series_request = {
    id: seriesId,
    require_series: {
      require_user_availability: true,
    },
  };
  return queryRequestItem;
};

const buildStoryUserAvailabilityRequest = (
  storyId: string,
  requestId: string
): QueryRequestItem => {
  const queryRequestItem = buildRequestItem(requestId);
  queryRequestItem.story_request = {
    id: storyId,
    require_story: {
      require_user_availability: true,
    },
  };
  return queryRequestItem;
};

const buildFollowingFollowerList = (
  followingFollowerListRequest: FollowingFollowerList,
  requestId: string
): QueryRequestItem => {
  const queryRequestItem = buildRequestItem(requestId);
  const listCursorRequest: UserCursorRequest = {
    require_user_list: buildUserFields(true, false, true, true),
    cursor: {
      cursor: followingFollowerListRequest.pagination.cursor || '',
      limit: followingFollowerListRequest.pagination.limit,
      require_cursor_info: {
        require_has_next_page: true,
        require_end_cursor: true,
      },
    },
  };

  queryRequestItem.user_request = {
    id: followingFollowerListRequest.userId,
    require_user: {
      [followingFollowerListRequest.isFollow
        ? 'requireFollowee'
        : 'requireFollower']: listCursorRequest,
    },
  };
  return queryRequestItem;
};

/*
 * Pure call to QueryService.Query
 * ↓
 */

export const query = async (
  req: QueryRequest,
  idToken?: string
): Promise<QueryResponse> => {
  const params = idToken ? { params: { idToken: idToken } } : undefined;
  const { data } = await api.post(`${SERVICE_PATH}/Query`, req, params);

  return data;
};

/*
 * Wrapper for generating and sending a combination of multiple queries to send at once
 * Based on types defined at the beginning of this file
 * ↓
 */

export const quickQuery = (
  queries: QuickQueryParams[],
  idToken?: string
): Promise<QueryResponse> => {
  const requestList: QueryRequest['request_list'] = [];
  for (const q of queries) {
    if (q.meRequest) {
      requestList.push(buildMeRequest(q.requestId));
    }

    if (q.storyListFacet) {
      requestList.push(buildStoryListFacet(q.storyListFacet, q.requestId));
    }

    if (q.storyRankingRequest) {
      requestList.push(
        buildStoryRankingRequest(q.storyRankingRequest, q.requestId)
      );
    }

    if (q.seriesUserAvailabilityRequest) {
      requestList.push(
        buildSeriesUserAvailabilityRequest(
          q.seriesUserAvailabilityRequest.seriesId,
          q.requestId
        )
      );
    }
    if (q.storyUserAvailabilityRequest) {
      requestList.push(
        buildStoryUserAvailabilityRequest(
          q.storyUserAvailabilityRequest.storyId,
          q.requestId
        )
      );
    }

    if (q.followingFollowerListRequest) {
      requestList.push(
        buildFollowingFollowerList(q.followingFollowerListRequest, q.requestId)
      );
    }

    if (q.rawQueryRequest) {
      requestList.push({
        ...q.rawQueryRequest,
        request_id: q.requestId,
      });
    }
  }

  return query({ request_list: requestList }, idToken);
};
