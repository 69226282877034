import { isLocalStorageAvailable } from '~/utils/feature-check';

export class LocalStorageOperator {
  public getObject = (key: string): unknown | null => {
    if (!isLocalStorageAvailable()) {
      return null;
    }
    const json = window.localStorage.getItem(key);
    if (!json) {
      return null;
    }
    return JSON.parse(json);
  };

  public setObject = (key: string, value: unknown): void => {
    if (isLocalStorageAvailable()) {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  };

  public removeOject = (key: string): void => {
    if (isLocalStorageAvailable()) {
      window.localStorage.removeItem(key);
    }
  };
}
