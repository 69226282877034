import type { SnakeCasedPropertiesDeep } from 'type-fest';
import type { query_search_types } from '~/infra/api/rpc';

export enum SearchStoryDistinct {
  SEARCH_STORY_DISTINCT_UNSPECIFIED = 'SEARCH_STORY_DISTINCT_UNSPECIFIED',
  SEARCH_STORY_DISTINCT_SERIES_OR_STORY = 'SEARCH_STORY_DISTINCT_SERIES_OR_STORY',
}

export enum SearchStoryFacetType {
  SEARCH_STORY_FACET_TYPE_UNSPECIFIED = 'SEARCH_STORY_FACET_TYPE_UNSPECIFIED',
  SEARCH_STORY_FACET_TYPE_TAGS = 'SEARCH_STORY_FACET_TYPE_TAGS',
}

export enum SearchStorySortBy {
  SEARCH_STORY_SORT_BY_UNSPECIFIED = 'SEARCH_STORY_SORT_BY_UNSPECIFIED',
  SEARCH_STORY_SORT_BY_DEFAULT = 'SEARCH_STORY_SORT_BY_DEFAULT',
  SEARCH_STORY_SORT_BY_PUBLISHED_AT_DESC = 'SEARCH_STORY_SORT_BY_PUBLISHED_AT_DESC',
  SEARCH_STORY_SORT_BY_UPDATED_AT_DESC = 'SEARCH_STORY_SORT_BY_UPDATED_AT_DESC',
  SEARCH_STORY_SORT_BY_SERIES_INDEX_ASC = 'SEARCH_STORY_SORT_BY_SERIES_INDEX_ASC',
}

export enum SearchUserSortBy {
  SEARCH_USER_SORT_BY_UNSPECIFIED = 'SEARCH_USER_SORT_BY_UNSPECIFIED',
  SEARCH_USER_SORT_BY_DEFAULT = 'SEARCH_USER_SORT_BY_DEFAULT',
  SEARCH_USER_SORT_BY_LATEST_PUBLISHED_AT_DESC = 'SEARCH_USER_SORT_BY_LATEST_PUBLISHED_AT_DESC',
}

export enum StorySearchableAttribute {
  STORY_SEARCHABLE_ATTRIBUTE_UNSPECIFIED = 'STORY_SEARCHABLE_ATTRIBUTE_UNSPECIFIED',
  STORY_SEARCHABLE_ATTRIBUTE_TITLE = 'STORY_SEARCHABLE_ATTRIBUTE_TITLE',
  STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE = 'STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TITLE',
  STORY_SEARCHABLE_ATTRIBUTE_DESCRIPTION = 'STORY_SEARCHABLE_ATTRIBUTE_DESCRIPTION',
  STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION = 'STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_DESCRIPTION',
  STORY_SEARCHABLE_ATTRIBUTE_TAGS = 'STORY_SEARCHABLE_ATTRIBUTE_TAGS',
  STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS = 'STORY_SEARCHABLE_ATTRIBUTE_HIRAGANA_TAGS',
  STORY_SEARCHABLE_ATTRIBUTE_SERIES_TITLE = 'STORY_SEARCHABLE_ATTRIBUTE_SERIES_TITLE',
  STORY_SEARCHABLE_ATTRIBUTE_SERIES_HIRAGANA_TITLE = 'STORY_SEARCHABLE_ATTRIBUTE_SERIES_HIRAGANA_TITLE',
  STORY_SEARCHABLE_ATTRIBUTE_USER_NAME = 'STORY_SEARCHABLE_ATTRIBUTE_USER_NAME',
  STORY_SEARCHABLE_ATTRIBUTE_USER_HIRAGANA_NAME = 'STORY_SEARCHABLE_ATTRIBUTE_USER_HIRAGANA_NAME',
}

export enum SearchSeriesSortBy {
  SEARCH_SERIES_SORT_BY_UNSPECIFIED = 'SEARCH_SERIES_SORT_BY_UNSPECIFIED',
  SEARCH_SERIES_SORT_BY_DEFAULT = 'SEARCH_SERIES_SORT_BY_DEFAULT',
  SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_DESC = 'SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_DESC',
  SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_ASC = 'SEARCH_SERIES_SORT_BY_LATEST_PUBLISHED_AT_ASC',
}

export enum UserSearchableAttribute {
  USER_SEARCHABLE_ATTRIBUTE_UNSPECIFIED = 'USER_SEARCHABLE_ATTRIBUTE_UNSPECIFIED',
  USER_SEARCHABLE_ATTRIBUTE_NAME = 'USER_SEARCHABLE_ATTRIBUTE_NAME',
  USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_NAME = 'USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_NAME',
  USER_SEARCHABLE_ATTRIBUTE_PROFILE = 'USER_SEARCHABLE_ATTRIBUTE_PROFILE',
  USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PROFILE = 'USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PROFILE',
  USER_SEARCHABLE_ATTRIBUTE_PUBLISHED_STORY_TAGS = 'USER_SEARCHABLE_ATTRIBUTE_PUBLISHED_STORY_TAGS',
  USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PUBLISHED_STORY_TAGS = 'USER_SEARCHABLE_ATTRIBUTE_HIRAGANA_PUBLISHED_STORY_TAGS',
}

export type SearchFacetItem =
  SnakeCasedPropertiesDeep<query_search_types.ISearchFacetItem>;
